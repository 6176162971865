import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "./styles.scss";
import config from "../../network/config";
import useViewport from "../../hooks/useViewport";

const ClientsGrid = ({ data }) => {
  const { width } = useViewport();

  return (
    <div className="display">
    {data?.map((item, key) => (
      <div className="grid-item" key={item?.id}>
        <img
          alt={item?.name}
          src={`${config.WS_STORAGE_URL}${item?.image}`}
          className="clientImage"
        />
      </div>
    ))}
  </div>
  );
};

export default ClientsGrid;
