/* eslint-disable */
import { useMediaQuery } from "beautiful-react-hooks";
import React, { useEffect, useState } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import useViewport from "../../hooks/useViewport";
import config from "../../network/config";
import "./styles.scss";



const ProjectCarousel = ({
  chosenTag,
  data,
  isLoading,
  splitProjects,
  setSelectedYoutubeLink,
}) => {
  const isSmall = useMediaQuery("(max-width: 767px)");

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const { width } = useViewport();

  const [carouselProjects, setCarouselProjects] = useState([]);

  const projects = JSON.parse(data.data);

  const MODULUS = width > 576 ? (width > 767 ? 6 : 4) : 2;

  function transform2d(srcArray, modulus) {
    var result;
    if (modulus > 0) {
      result = [];
      while (srcArray?.length > 0) {
        result.push(srcArray?.splice(0, modulus));
      }
    }
    return result;
  }

  const getProjects = () => {
    if (chosenTag === "all") {
      return projects;
    } else {
      return JSON.parse(splitProjects.find((x) => x.id === chosenTag).array);
    }
  };

  const displayProjects = () => {
    setCarouselProjects(transform2d(getProjects(), MODULUS));
  };

  useEffect(() => {
    displayProjects();
  }, [width, chosenTag, isLoading]);

  useEffect(() => {
    setIndex(0);
  }, [width, chosenTag]);

  return (
    <div style={{ paddingBottom: 60 }}>
      <Container fluid className="projectCarouselContainer">
        {isSmall && (
          <div
            style={{
              width: "100%",
              display: "flex",
              placeContent: "center",
              paddingBottom: 24,
              fontFamily: "NexaRegular",
            }}
          >
            <span style={{ color: "white", textAlign: "center" }}>
              {" "}
              {index + 1}/{carouselProjects.length}
            </span>
          </div>
        )}
        <Carousel
          indicators={true}
          className="projectCarousel"
          activeIndex={index}
          onSelect={handleSelect}
          prevIcon={<FaChevronLeft size={40} />}
          nextIcon={<FaChevronRight size={40} />}
        >
          {carouselProjects?.map((crslItem, key) => (
            <Carousel.Item key={key}>
              <Row>
                {crslItem?.map((item, projIndex) => (
                  <Col
                    key={projIndex}
                    xs={12}
                    sm={6}
                    md={4}
                    onClick={() => {
                      if (item?.url) setSelectedYoutubeLink(item?.url);
                    }}
                    className="projectItem"
                  >
                    <div
                      className="projectBackground"
                      style={{
                        backgroundImage: `url(${config.WS_STORAGE_URL}${item?.thumbnail})`,
                        backgroundSize: "cover",
                      }}
                    />
                    <div className="projectInfo">
                      <p className="projectTitle">{item.name}</p>
                      <p className="projectClientName">{item?.clientName}</p>
                      {/* <p className="projectCategories">
                        {item?.categories?.map((cat, index) =>
                          index !== item?.categories?.length - 1
                            ? cat.categories_id.name + ", "
                            : cat.categories_id.name
                        )}
                      </p> */}
                      
                      {item?.details != null && (
                        <div className="projectDetails" dangerouslySetInnerHTML={{__html: item?.details}}>
                                           
                        </div>
                      )}
                     
              
                    </div>
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default ProjectCarousel;
