import React from "react";
import { Container } from "react-bootstrap";
import ClientCarousel from "../../components/ClientCarousel";
import ClientsGrid from "../../components/ClientsGrid";

import "./styles.scss";

const Clients = ({ data }) => {
  return (
    <div className="clientsWrapper">
      <Container className="clientsContainer">
        <h2 className="heading bold">Clients</h2>
        <ClientsGrid data={data} />
      </Container>
    </div>
  );
};

export default Clients;
